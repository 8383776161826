
import { Vector2d } from "~src/modules/bounds/vector2d";
import { CanvasPosition } from "~src/modules/bounds/world-coords";
import { ObjectBase } from "../../object/engine-object-base";
import { Player } from "../../object/engine-object-player";
import { EntityBase } from "../engine-entity-base";


export type TouchListenerCallback = ( touches:Touch[]|TouchList, event?:TouchEvent )=>void

export type TouchListenerIterator = ( touch:Touch, touches:Touch[]|TouchList, event?:TouchEvent )=>void


export class EntityToolBase extends EntityBase {
    protected _defaultEventSubject : HTMLElement = document.body;
    protected events : [HTMLElement,  string, (event: Event )=>void][] = [];
    _host:ObjectBase;
    _activate = false;
    protected focusPoint = new Vector2d();

    equip(host:ObjectBase){
        this._host = host;
        // this.listen('mousedown',
        // (event:MouseEvent)=>{
        //     this._mouseDown = true;
        //     let dpi = window.devicePixelRatio;
        //     this._mousePos.x = event.pageX * dpi - window.document.body.clientWidth/2;
        //     this._mousePos.y = event.pageY * dpi - window.document.body.clientHeight/2;
        // });

        // this.listen('mouseup',
        // (event:MouseEvent)=>{
        //     this._mouseDown = false;
            
        // });
    
    
        // this.listen('mousemove', (event:MouseEvent) => {
        //     let dpi = window.devicePixelRatio;
        //     this._mousePos.x = event.pageX * dpi - window.document.body.clientWidth/2;
        //     this._mousePos.y = event.pageY * dpi - window.document.body.clientHeight/2;
        // });

    }


    update(mainCtx:CanvasRenderingContext2D){

    }


    unequip(){
        this._host = null;
        for(const [subject, eventName, callback] of this.events) {
            subject.removeEventListener( eventName, callback);
        }
        this.events = [];
    }


    draw(ctx:CanvasRenderingContext2D, canvasPosition: CanvasPosition){

    }



    getFocusPoint(){
        return this.focusPoint;
    }

    setFocusPoint(newPos:Vector2d){
        this.focusPoint = newPos;
    }

    setAcctivate(doActivate:boolean){
        this._activate = doActivate;
    }

    // =============================================
    //          EVENT MANAGEMENT
    // ==============================================

    listen(eventName: string, callback : (event: Event )=>void, subject?:HTMLElement ) {
        if(!subject) {
            subject=this._defaultEventSubject;
        }
        this.events.push([subject, eventName, callback]);
        subject.addEventListener(eventName, callback);
        
    }

    touchListen(touchEvents: { start ?: TouchListenerCallback , change ?: TouchListenerCallback , end ?: TouchListenerCallback, cancel ?: TouchListenerCallback,
        startEach?:TouchListenerIterator, changeEach?:TouchListenerIterator, endEach?:TouchListenerIterator, cancelEach?:TouchListenerIterator  } ) {

        
        let types = {start:{event:"touchstart",
                touches: "targetTouches"}, 
            change:{event:"touchmove",
                touches: "changedTouches"},
            end:{event:"touchend",
                touches: "changedTouches"},
            cancel:{event:"touchcancel",
                touches: "changedTouches"} }
        
        Object.keys(types).forEach(key=>{
            const eventData = types[key];
            const eachKey = `${key}Each`
            if(touchEvents[key] || touchEvents[eachKey]) {
                this.listen( eventData.event, (event:Event) =>{
                    const touchEvent = event as TouchEvent
                    // touchEvent.preventDefault();
                    touchEvent.stopPropagation();

                    // free up that input thread
                    window.setTimeout(()=>{
                        const touches = touchEvent[eventData.touches];
                        if(touchEvents[key]) touchEvents[key](touches,touchEvent);
                        if(touchEvents[eachKey]) {
                            for(const touch of touches){
                                touchEvents[eachKey](touch, touches, touchEvent)
                            }
                        }
                    }, 0);


                });
            }
        })


    }


}