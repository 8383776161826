import { ObjectBase } from "./modules/engine/object/engine-object-base";

var server = { urls: "stun:stun.l.google.com:19302" };
const pc = new RTCPeerConnection({ iceServers: [server] });


let expChannel =null;
let testChannel;
const channels = {};
const onMessages={};


window["channels"] = channels

pc.ondatachannel = e => {
  // 'channel.label'
  console.log('ondatachannel', e)
  
  if(!e.channel) return;
  channels[e.channel.label] = e.channel;
  // if(onMessages[e.channel.label]){
  //   e.channel.onmessage = onMessages[e.channel.label]
  // }

  // if(e.channel.label =='testchannel'){
  //   testChannel = pc.createDataChannel('testchannel')
  //   dcinit();
  // }
  // e.channel.onopen= console.log
  
}



export const getChannel = (name:string) => {
  return channels[name];
}

export const getOrCreateChannel = (name:string, onMessage: (evt:MessageEvent<any>)=>void) => {
  if(!channels[name]){
    const newChannel : RTCDataChannel = pc.createDataChannel(name);
    channels[name] = newChannel;
    newChannel.onmessage = onMessage;
    onMessages[name] = onMessage;
  }
  return channels[name];
}

export const sendOnChannel = (channelName:string, message:string|{}) => {
  if(!channels[channelName] || (channels[channelName].readyState != "open") ) return ;
  channels[channelName].send(JSON.stringify(message));
}






pc.oniceconnectionstatechange = e => log(pc.iceConnectionState);



// dc = pc.createDataChannel("chat")
// dc.onopen = () => log("Chat!");

const dcinit = ()=>{
  testChannel.onopen=console.log
  testChannel.onmessage=console.log
}

window["host"] = () => {
  // button.disabled = true;

  // ObjectBase.init();
  testChannel = pc.createDataChannel('testchannel')
  dcinit();

  console.log('hosting')

  pc.createOffer().then(d => pc.setLocalDescription(d))
  
  pc.onicecandidate = e => {

    if (e.candidate) return;
    const localDesc = pc.localDescription.sdp.replaceAll('\n', "\\n")
    // console.log('offer', localDesc)
    console.log(btoa(localDesc))
    
  };

  
};



window["connect"] = () =>{


  if (pc.signalingState != "stable") return;
  const offer = atob(window.prompt("Offer")).replaceAll("\\n", '\n')
  var desc = new RTCSessionDescription({ type:"offer", sdp:offer });



  pc.setRemoteDescription(desc)
  .then(() => pc.createAnswer()).then(answer => {
    pc.setLocalDescription(answer)
  } )
  .catch(log);

  console.log('where ')
  pc.onicecandidate = e => {

    if (e.candidate) return;
    const answer = pc.localDescription.sdp.replaceAll( '\n', "\\n")
    console.log( btoa(answer))
    // alert(answer)
  };



} // end of connect

window["answer"] = ()=>{
  console.log('pc signalstate', pc.signalingState)
  // if (pc.signalingState != "have-local-offer") return;

  pc.onsignalingstatechange = (e) => {
    console.log('signal state changed', e)
    console.log('signal state', pc.signalingState)
  }

  const answer = atob(window.prompt("answer")).replaceAll("\\n", '\n');
  var desc = new RTCSessionDescription({ type:"answer", sdp:answer });
  pc.setRemoteDescription(desc).then(e=>{
    console.log('remote-set', e)
    
  })
  .catch(log);


  console.log('done setting remote' );
};




var log = console.log