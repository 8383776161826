import { Vector2d } from "~src/modules/bounds/vector2d";
import { Ice } from "../../object/engine-object-ice";
import { Player } from "../../object/engine-object-player";
import { EntityToolBase } from "./engine-entity-tool-base";


export class IceGun extends EntityToolBase {

    lastShootTime:number=performance.now();
    _roundDelay = 100;
    _damageSize = 4;
    _kickback = 4;

    constructor(roundDelay:number, damageSize:number, kickback=1.0){
        super();
        this._roundDelay = roundDelay;
        this._damageSize = damageSize;
        this._kickback = kickback;
    }

    equip(newHost:Player){
        super.equip(newHost);

        
    }

    unequip(){
        super.unequip();
        
    }

    update(){
        const focus = this.getFocusPoint();
        if(focus && performance.now() - this.lastShootTime >this._roundDelay) {
            const iceLocation = focus.clone();

            const hostPos = this._host.pos;
            iceLocation.x = focus.x + hostPos.x;
            iceLocation.y = focus.y + hostPos.y;
            
            const intersect = this._host.rayCast( hostPos, iceLocation);

            if(intersect.object instanceof Ice) {
                const otherIce = intersect.object as Ice;
                otherIce.addIce(iceLocation.x,iceLocation.y);
            } else{
                this._host.getObjectList().push(new Ice(iceLocation.x, iceLocation.y,this._damageSize,this._damageSize));
            }

            
            this.lastShootTime = performance.now();
        }
    }

    draw(ctx:CanvasRenderingContext2D){
        ctx.beginPath();
        const hostPos = this._host.pos;
        ctx.arc(hostPos.x + this.getFocusPoint().x, hostPos.y + this.getFocusPoint().y, 3,0,Math.PI*2);
        ctx.strokeStyle="#0088ff"
        ctx.stroke();
        
    }
}