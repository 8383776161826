import { Vector2d } from "~src/modules/bounds/vector2d";
import { expl, ObjectBase } from "./engine-object-base";


const tempCanvas = document.createElement('canvas');



let loaded = false;
export class LayeredObject extends ObjectBase{

    layerCans:HTMLCanvasElement[] = [];

    constructor(x,y, path, layers, width=null, height=null) {
        super();

        let fixedWidth = false;
        if(width && height){
            fixedWidth = true;

        } else{
            width =320;
            height=320
        }

        this.setLayers(layers);
        this.width  =   width;
        this.height =   height;
        this.canvas.width = width * (this.layers+1);
        this.canvas.height = height;

        this.pos = new Vector2d(x,y);

        const textureImage = document.createElement('img');
        textureImage.src = path;

        const doDraw = ()=>{
            console.log('draw concrete')
            const ctx = this.canvas.getContext('2d');

            if(fixedWidth) {
                this.width  =   textureImage.width / this.layers;
                this.height =   textureImage.height;
                this.canvas.width = this.width * (this.layers+1);
                this.canvas.height = this.height;
            }

            loaded = true;

        // ctx.fillStyle="#884411"
        // ctx.rect(0, 0, this.width, this.height);
            
            // ctx.drawImage(textureImage, this.width,0, textureImage.width,this.height);

            for(let i=0;i<this.layers;i++){
                const can = this.layerCans[i];
                can.width = this.width;
                can.height = this.height;
                const ctx = can.getContext('2d');
                ctx.drawImage(textureImage, i*width, 0,this.width, this.height,
                    0,0, this.width,this.height)
            }


            this.refreshImageData();
            
        }

        window.setTimeout(doDraw, 1000);
        // ctx.fill();
        // ctx.stroke();

    }

    public refreshImageData(){
        // const startTime = performance.now()

        const ctx = this.canvas.getContext('2d');
        
        // collapse the layers
        ctx.clearRect(0,0,this.width,this.height)
        for(let i=this.layerCans.length; i>=0; i--){
            if(!this.layerCans[i]) continue;
            ctx.drawImage(this.layerCans[i], 0, 0, this.width, this.height)
        }
        
        // cache the col data
        
        
        tempCanvas.width = this.width;
        tempCanvas.height = this.height;
        const tempCTX = tempCanvas.getContext('2d');
        tempCTX.drawImage(this.canvas,0,0, this.width, this.height, 0,0, this.width, this.height);
        
        this._bounds.width = this.width;
        this._bounds.height = this.height;
        this.pixelData = tempCTX.getImageData(0,0, tempCanvas.width, tempCanvas.height);
    }

    setLayers(newLayers){
        super.setLayers(newLayers);

        this.layerCans = [];
        for(let i=0;i<newLayers;i++){
            const can = document.createElement('canvas');
            this.layerCans[i] = can;
        }
    }

    getLayerAverage(layerNumber:number){
        const can = document.createElement('canvas');
        can.width = 2
        can.height = 2;

        const con = can.getContext('2d');
        con.drawImage(this.canvas,this.width*(layerNumber+1), 0,this.canvas.width,this.height, 
            0,0,can.width,can.height);
        const imageData = con.getImageData(0,0,can.width,can.height);
        return (imageData.data[3] + imageData.data[7])/2;
    }


    receiveExplosion(pos:Vector2d, size:number, getRand:()=>number = Math.random){
        if(pos.x > this.pos.x + this.width + size) return;
        if(pos.x < this.pos.x - size) return;


        const ctx = this.canvas.getContext('2d');
        const relativeLocation = pos.clone();
        relativeLocation.sub(this.pos);
        ctx.save();

        
        // ctx.beginPath();
        // ctx.arc(relativeLocation.x,relativeLocation.y,size,0, Math.PI*2);
        // ctx.clip();
        // ctx.fillStyle= "#000"
        
        // ctx.closePath();
        // ctx.fill();

        // ctx.translate(relativeLocation.x , relativeLocation.y);
        

        for(let i=0; i<this.layers && size>=1; i++){
            const layerCtx = this.layerCans[i].getContext('2d');
            layerCtx.globalCompositeOperation = 'destination-out'
            layerCtx.save();
            
            layerCtx.translate(relativeLocation.x, relativeLocation.y);
        
            
            let angle =getRand()*Math.PI*2
            
            
            layerCtx.rotate(angle);
            // ctx.scale(1 + .1* Math.random(),1 + .1* Math.random())
            
            layerCtx.drawImage(expl, -size, -size, size*2, size*2);
            // ctx.rotate(-angle);
            let newSize = size *0.5;
            if(size - newSize< 5) newSize = size - 5;
            size = newSize;
            // ctx.clearRect(relativeLocation.x-size, relativeLocation.y-size, size*2, size*2 );
            layerCtx.translate( - (relativeLocation.x + this.width*i), -relativeLocation.y);
            layerCtx.restore();
        }

        ctx.restore();

        this.refreshImageData();
    }

}