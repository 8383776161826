import { Vector2 } from "three";
import { Vector2d } from "~src/modules/bounds/vector2d";
import { CanvasPosition } from "~src/modules/bounds/world-coords";
import { EntityGun, getFile } from "../entity/tool/engine-entity-gun";
import { IceGun } from "../entity/tool/engine-entity-ice-gun";
import { EntityToolBase } from "../entity/tool/engine-entity-tool-base";
import { ObjectBase } from "./engine-object-base";
import handgunMp3 from "url:~/sounds/handgun.mp3"
import akMp3 from "url:~/sounds/ak.mp3"
import bigGunMp3 from "url:~/sounds/big-gun.mp3"
import { sendOnChannel } from "~src/online";
import { EntityLibraryTool } from "../entity/tool/engine-entity-tool-library";
// import { sendOnChannel } from "~src/online";


const AudioContext = window.AudioContext;
const audioCtx:AudioContext = new AudioContext();


const absoluteFloor = 1200;


export class Player extends ObjectBase{

    vel:Vector2d = new Vector2d();

    currentKeys = {};

    lastTime:number = performance.now();
    secondary:HTMLCanvasElement;
    ctxB:CanvasRenderingContext2D;
    onGround = false;
    protected _mouseDown = false;
    protected _mousePos = new Vector2d();
    protected events : [HTMLElement,  string, (event: Event )=>void][] = [];
    protected _canvasPos:CanvasPosition
    protected playerName:string =""

    constructor(canvasPos:CanvasPosition) {
        super();
        this.playerName = ""+Math.floor(Math.random()*1000000000.0)
        this.width  =  16;
        this.height =   32;
        this.canvas.width = this.width;
        this.canvas.height = this.height;
        this.pos.x = 100;
        this.pos.y = 100;
        this._canvasPos = canvasPos;

        const ctx = this.canvas.getContext('2d');

        ctx.fillStyle="#220000"
        const headWidth = 5;
        const bodyWidth = 16;
        const bodyHeight = this.height - headWidth;
        ctx.rect( (this.width - headWidth)/2, 0, headWidth, headWidth);
        ctx.rect( (this.width - bodyWidth)/2, headWidth, bodyWidth, bodyHeight);
        ctx.fill();
        ctx.stroke();

        this.secondary = document.createElement("canvas")
        this.ctxB = this.secondary.getContext("2d");
        
        this.secondary.width = this.width+20;
        this.secondary.height = this.height+20;

        // =======================
        //      TOOLS
        // ======================= 
        const setGunSound = (promise:Promise<AudioBuffer>, gun:EntityGun)=>{
            promise.then(data=>gun.shotSound=data)
        }
        const handgunSound = getFile(handgunMp3);
        const akSound = getFile(akMp3);
        const bigGunSound = getFile(bigGunMp3);

        const revolver = new EntityGun(500, 30);
        setGunSound(bigGunSound, revolver);

        const pistol = new EntityGun(350, 5, 10, 20);
        setGunSound(handgunSound, pistol);

        const uzi = new EntityGun(80, 4, 16, 100);
        setGunSound(akSound, uzi);
        const iceGun = new IceGun(150, 100, 100);
        const toolGun = new EntityLibraryTool();

        const tools = [null, revolver, uzi, pistol,toolGun];
        
        this.equipTool(toolGun)


        // ======================================
        ///         event listeners
        // ======================================

        window.addEventListener('keydown',(event)=>{


            if(event.key==" ") {

                const explosionLocation = this.pos.clone();
                explosionLocation.x += this.width/2;
                explosionLocation.y += this.height
                this.explode( explosionLocation, 80, this);
            }

            // try to equip a tool
            if(tools[event.key]){
                this.equipTool(tools[event.key]);
            }

            this.currentKeys[event.key] =true;
        })

        window.addEventListener('keyup',(event)=>{

            this.currentKeys[event.key] =false;
        })

        
        this.listen('mousedown',
        (event:MouseEvent)=>{
            this._mouseDown = true;
            this._mousePos.x = event.clientX;
            this._mousePos.y = event.clientY;

        });

        this.listen('mouseup',
        (event:MouseEvent)=>{
            this._mousePos.x = event.clientX;
            this._mousePos.y = event.clientY;
            this._mouseDown = false;
        });
    
    
        this.listen('mousemove', (event:MouseEvent) => {
            this._mousePos.x = event.clientX;
            this._mousePos.y = event.clientY;
        });

        this.lastTime =  performance.now();
    }

    listen(eventName: string, callback : (event: Event )=>void ) {
        const subject = document.body;
        this.events.push([subject, eventName, callback]);
        subject.addEventListener(eventName, callback);
        
    }


    // =====================================================
    //                      UPDATE
    // =====================================================
    update(mainCtx:CanvasRenderingContext2D,cameraPos ){
        super.update(mainCtx,cameraPos)
        
        const elapsedTime = performance.now() - this.lastTime;
        this.lastTime = performance.now();

        let deltaTime = elapsedTime / 1000.0;
        // if(deltaTime<0.004) deltaTime = 0.004;
        if(deltaTime>0.01) deltaTime = 0.01;

        const newPos = this.pos.clone();
        this.vel.y += 3024.0 * deltaTime;
        

        //friction
        if(this.onGround) {

            if(Math.abs(this.vel.x) > 0.05 ){
                let positive = this.vel.x > 0;

                let friction = 800.0 * deltaTime;
                if(positive) friction*=-1;

                this.vel.x += friction;
                if( (this.vel.x <0 && positive) 
                    || (!positive && this.vel.x >0) ){
                        this.vel.x=0;
                }
            } else {
                this.vel.x = 0;
            }
        }

        // JUMPIN/JETPACKIN
        if(this.currentKeys["w"]){
            if(this.onGround) {
                this.vel.y += -600;
            } else {
                this.vel.y += -4000 * deltaTime;
            }
        }

        // lteral movement
        if(this.currentKeys["d"]){
            if(this.onGround) {
                this.vel.x += 1024*deltaTime;
            } else {
                this.vel.x += 400*deltaTime;
            }
        }

        if(this.currentKeys["a"]){
            if(this.onGround) {
                this.vel.x -= 1024*deltaTime;
            } else {
                this.vel.x -= 400*deltaTime;
            }
        }



        newPos.x += this.vel.x * deltaTime;
        newPos.y += this.vel.y * deltaTime;
        // const imageData = mainCtx.getImageData(0,0,mainCtx.canvas.width,mainCtx.canvas.height);
        
        // this.secondary = document.createElement("canvas")
        // this.ctxB = this.secondary.getContext("2d");
        
        this.secondary.width = this.width+20;
        this.secondary.height = this.height+20;
        
        const startTime = performance.now();
        const secondary = this.secondary;
        const ctxB = secondary.getContext('2d');
        
        ctxB.save();
        // this.ctxB.globalCompositeOperation="copy";

        const pixelDataX = Math.min(this.pos.x, newPos.x);//Math.round(mainCtx.canvas.width/2 - 10);
        const pixelDataY = Math.min(this.pos.y, newPos.y);//Math.round(mainCtx.canvas.height/2);
        
        ctxB.translate(-pixelDataX,-pixelDataY)
        const objectList = this.getObjectList();
        for(const object of objectList) {
            // console.log('drawing all objects')
            if(object == this) continue;
            ctxB.drawImage(
                object.canvas,
                0, 0, object.width , object.height, // src region
                object.pos.x, object.pos.y, object.width , object.height //  dst region
            );
            
        
        }

        ctxB.translate(pixelDataX,pixelDataY)

        // ctxB.drawImage(
        //     mainCtx.canvas,
        //     Math.round(mainCtx.canvas.width/2 - 10) , Math.round(mainCtx.canvas.height/2) , secondary.width , secondary.height, // src region
        //     0, 0, secondary.width , secondary.height //  dst region
        // );

        const imageData = ctxB.getImageData(0,0,secondary.width, secondary.height);
        ctxB.restore();
        // ctxB.
        // const secondImageData = ctxB.getImageData(0,0,secondary.width, secondary.height);

        const getPixelColor = (x,y,offset=0) => {
            
            x = Math.round(x-pixelDataX);
            y = Math.round(y-pixelDataY);
            
            // return imageData.data[ 0 + offset ]
            let pos = (y * imageData.width + x )*4 + offset;
            if(x>=imageData.width) return 0;
            if(x<0) return 0;
            if(pos<0) return 0;
            if(pos>=secondary.width * secondary.height*4) return 0;
            return imageData.data[ pos ]
            
        }
        
        
        // check for collision
        this.onGround = false;
    
        if(this.vel.y>0) {
            if(newPos.y > absoluteFloor-this.height) {
                newPos.y = absoluteFloor-this.height;
                this.onGround=true;
                this.vel.y = this.vel.y*-0.9;
            } else {
                for(let y = this.pos.y+2; y< newPos.y+this.height;y++) {
                    for(let x=3; x<10;x++) {
                        const color = getPixelColor(newPos.x+x, y,3);

                        if(color) {
                            
                            newPos.y = y-this.height;
                            // if(getPixelColor(newPos.x+x, newPos.y+this.,3)) {
                            //     newPos.y = y;
                            // }
                            let velYMag = Math.abs(this.vel.y * 0.25);
                            if(velYMag < 0.01) velYMag = 0;
                            
                            
                            if(this.vel.y > 3000) {
                                const explSize = ((this.vel.y-3000)/250)
                                this.explode(newPos.plus(this.width/2,this.height),explSize,this)
                                this.vel.y*=0.8;
                            } else {

                                this.vel.y = -velYMag;

                                this.onGround = true;
                            }
                            // y=this.pos.y-1;
                            break;
                        }
                    }
                }
            }
        } else if(this.vel.y<0) {

            for(let y = this.pos.y; y> newPos.y-1; y--) {
                for(let x=3; x<10;x++) {
                    const color = getPixelColor(newPos.x+x, y,3);
                    
                    if(color) {
                        
                        newPos.y = y;
                        // if(getPixelColor(newPos.x+x, newPos.y+this.,3)) {
                        //     newPos.y = y;
                        // }
                        let velYMag = Math.abs(this.vel.y * 0.25);
                        if(velYMag < 0.01) velYMag = 0;
                        this.vel.y = -velYMag;
                        // this.onGround = true;
                        // y=this.pos.y-1;
                        y = newPos.y
                        break;
                    }
                }
            }
        }


        

        // take care of horizontal collision
        if(Math.abs(this.vel.x)>0) {

            let maxX = Math.max(this.pos.x, newPos.x);
            let minX = Math.min(this.pos.x, newPos.x);
            let pixelOffset = this.width;
            let addOrSub = (val)=>{
                return val+1;
            }
            let compare = (x)=>{
                return x<maxX;
            }
            let sign = -1;
            

            if(this.vel.x<0) {
                addOrSub = (val)=>{
                    return val-1;
                }

                compare = (x)=>{
                    return (x)>minX;
                }
                pixelOffset = 0;
                sign = 1;
            }

            
            for(let x=this.pos.x; compare(x); x = addOrSub(x)) {
                for(let y = newPos.y; y< newPos.y+2*this.height / 3; y++) {
                    
                    const color = getPixelColor(x + pixelOffset, y,3);
                    
                    if(color) {
                        
                        newPos.x = x;

                        // if(getPixelColor(newPos.x+x, newPos.y+this.,3)) {
                        //     newPos.y = y;
                        // }
                        let velXMag = Math.abs(this.vel.x * 0.25);
                        if(velXMag < 0.01) velXMag = 0;
                        this.vel.x = velXMag*sign;
                        // this.onGround = true;
                        // y=this.pos.y-1;
                        break;
                    }
                }
            }
        }




        

        

        // console.log('done', performance.now() - startTime );

        this.pos = newPos;
        sendOnChannel("objData", {type:'player', name:this.playerName, pos:{x:this.pos.x, y:this.pos.y}} )
        
        this.bounds.x = this.pos.x;
        this.bounds.y = this.pos.y;
        
    }

    updateTools(mainCtx, cameraPos){
        //update tools
        if(this._tool){
            this._tool.setFocusPoint(this._canvasPos.paperCoords(this._mousePos.clone()))
            this._tool.setAcctivate(this._mouseDown);
        }
        super.updateTools(mainCtx, cameraPos);
    }

    playerPos : {[key:string]:Vector2d} = {}
    draw(ctx:CanvasRenderingContext2D, canvasPos:CanvasPosition) {

        const absFloorDis = Math.abs(this.pos.y - absoluteFloor);
        const floorRange = 200;
        if(absFloorDis <floorRange){
            ctx.save();

            
            ctx.strokeStyle="#55ccffaa"
            ctx.lineWidth = 5;

            for(let i=0; i< 24 * (1.0 - absFloorDis/floorRange); i++) {
                ctx.beginPath();
                let dist = (Math.random()**2)*16;
                if(i==0) dist = 0;
                ctx.moveTo(this.pos.x-30 + dist, absoluteFloor + 3 + (dist*3  ) * (i>0 ? 1 : 0));
                ctx.lineTo(this.pos.x+ 30 -dist, absoluteFloor + 3 + (dist*3  )* (i>0 ? 1 : 0));
                
                ctx.stroke();
                ctx.lineWidth = Math.random() * (16 - dist);
                ctx.globalCompositeOperation = "screen"
                ctx.strokeStyle="#6622aa10"
            }
            
            ctx.restore();
        }

        super.draw(ctx, canvasPos);
        // draw the current tool
        if(this._tool){
            this._tool.draw(ctx, canvasPos);
        }

        ctx.fillText(this.playerName, this.pos.x, this.pos.y)


        ObjectBase.evtQueue.forEach( (evt,index) =>{
            switch(evt.type){

                case 'player':
                    const enemyData = evt as {pos:{x:number, y:number}, name:string};
                    this.playerPos[enemyData.name] = new Vector2d(enemyData.pos);
                    
                    
            }
            ObjectBase.evtQueue.splice(index,-1)
        });

        Object.keys(this.playerPos).forEach(playerName=>{
            const player = this.playerPos[playerName];
            ctx.drawImage(this.canvas, player.x, player.y)
            ctx.fillText(playerName, player.x, player.y)
        });

        
 

        // ctx.beginPath()
        // ctx.moveTo(this.pos.x, this.pos.y)
        // ctx.strokeRect(this.pos.x, this.pos.y, this.secondary.width, this.secondary.height)
        // ctx.drawImage(this.secondary,this.pos.x,this.pos.y)
    
    }

    receiveExplosion(){
        
    }


    



}