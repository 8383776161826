import { Vector2d } from "~src/modules/bounds/vector2d";
import { ObjectBase } from "./engine-object-base";

import concreteImage from '~/img/concrete.png'
import rebarImage from '~/img/rebar.png'

const concreteTexture = document.createElement('img');
const rebarTexture = document.createElement('img');
concreteTexture.src=concreteImage
rebarTexture.src=rebarImage;

let loaded = false;
export class Block extends ObjectBase{

    

    constructor(x,y, width:number, height:number) {
        super();
        this.width  =   width;
        this.height =   height
        this.bounds.width = width;
        this.bounds.height = height;
        this.setLayers(2);

        this.canvas.width = width * (this.layers+1);
        this.canvas.height = height;

        this.pos = new Vector2d(x,y);

        

        const drawConcrete = ()=>{
            console.log('draw concrete')
            const ctx = this.canvas.getContext('2d');

            loaded = true;

        // ctx.fillStyle="#884411"
        // ctx.rect(0, 0, this.width, this.height);
            
            ctx.drawImage(concreteTexture, 0,0,this.width,this.height, this.width,0, this.width,this.height);
            ctx.drawImage(rebarTexture, this.width*2,0);


            this.refreshImageData();
            
        }

        window.setTimeout(drawConcrete, 300);
        // ctx.fill();
        // ctx.stroke();

    }




}