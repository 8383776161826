import { Audio, Vector2 } from "three";
import { Vector2d } from "~src/modules/bounds/vector2d";
import { Player } from "../../object/engine-object-player";
import { DelayTimer } from "../../util/engine-util-delay-timer";
import { EntityToolBase } from "./engine-entity-tool-base";


const AudioContext = window.AudioContext;
const audioCtx:AudioContext = new AudioContext();
export const getFile = async (filepath) => {
    const response = await fetch(filepath);
    const arrayBuffer = await response.arrayBuffer();
    const audioBuffer = await audioCtx.decodeAudioData(arrayBuffer);
    return audioBuffer;
  }



const playTrack = (audioBuffer, offset=0) => {


    const gainNode = audioCtx.createGain()
    if (audioCtx.state === 'suspended') {
        audioCtx.resume();
    }
    const trackSource = audioCtx.createBufferSource();
    trackSource.buffer = audioBuffer;
    trackSource.playbackRate.value = 1.3 - Math.random()*0.6
    trackSource.connect(gainNode)
    gainNode.gain.value=Math.random()*0.2 +0.8
    gainNode.connect(audioCtx.destination);
    trackSource.start(0);

    // console.log('playTrack')
}

export class EntityGun extends EntityToolBase {

    lastShootTime:number=performance.now();
    _roundDelay = 100;
    _damageSize = 4;
    _kickback = 4;
    _range = 800;
    _clip = 0;
    _clipSize = 8;
    _reloadTimer = new DelayTimer(1000)

    public shotSound:AudioBuffer;

    _shotQueue :Vector2d[] = [];

    constructor(roundDelay:number, damageSize:number,clipSize=8, kickback=1.0){
        super();
        this._roundDelay = roundDelay;
        this._damageSize = damageSize;
        this._clipSize = clipSize;
        this._kickback = kickback;
    }

    equip(newHost:Player){
        super.equip(newHost);

        
    }

    unequip(){
        super.unequip();
        
    }



    update(){


        if(this._reloadTimer.isReady() && this._activate && performance.now() - this.lastShootTime >this._roundDelay) {

            // console.log('CLIIP', this._clip)
            //check ammo
            if(this._clip <=0) {
                this._clip = this._clipSize;
                this._reloadTimer.reset();
                // console.log('reload')
                return;
            }

            this._clip--;
            if(this.shotSound){
                playTrack(this.shotSound);
            }
            const explosionLocation = this.getFocusPoint().clone();
            // console.log('shoot')
            const hostPos = this._host.pos.clone();
            // hostPos.add(0,-10)
            explosionLocation.x = this.getFocusPoint().x ;
            explosionLocation.y = this.getFocusPoint().y;
            
            // console.log('explosionLocation', explosionLocation, explosionLocation.length)

            const ray = explosionLocation.minus(hostPos);

            ray.length = this._range;
            // console.log('explosionLocation', explosionLocation, explosionLocation.length)

            const intersect = this._host.rayCast( hostPos, 
                hostPos.plus(ray).add(+ this._kickback*(Math.random()-0.5),+ this._kickback*(Math.random()-0.5)),
                (object) => (object == this._host) );

            // if we hit something then handle it
            if(intersect){
                this._host.explode(new Vector2d(intersect.pos), this._damageSize, this._host);
                this._shotQueue.push( new Vector2d(intersect.pos) );
            } else {
                this._shotQueue.push( this._host.pos.plus(ray) );
            }

            this.lastShootTime = performance.now();
            
        }
    }



    draw(ctx:CanvasRenderingContext2D){

        const hostPos = this._host.pos;


        // ctx.beginPath();
        // ctx.lineWidth=1
        
        // ctx.arc(hostPos.x + this.getFocusPoint().x, hostPos.y + this.getFocusPoint().y, 3,0,Math.PI*2);
        // ctx.strokeStyle="#000000"

 
        const shotPercent = Math.min(1.0, (performance.now() - this.lastShootTime)/this._roundDelay);
        ctx.beginPath();
        ctx.arc( this.getFocusPoint().x,  this.getFocusPoint().y, 8,0,Math.PI*2 * shotPercent);
        ctx.strokeStyle="#000000"
        ctx.stroke();

        
        // draw each of the shots
        if(this._shotQueue.length>0){
            ctx.lineWidth=3;
            ctx.strokeStyle="#ff8800"
            this._shotQueue.forEach(shot=>{
                ctx.beginPath();
                ctx.moveTo(hostPos.x , hostPos.y );
                ctx.lineTo(shot.x , shot.y );
                
                ctx.stroke();
            });

            this._shotQueue =[];
        }
        
        
    }
}