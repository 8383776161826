import { Vector2d } from "~src/modules/bounds/vector2d";
import explImage from '~/img/explosion.png'
import { Bounds } from "~src/modules/bounds/bounds";
import { EntityToolBase } from "../entity/tool/engine-entity-tool-base";
import { CanvasPosition } from "~src/modules/bounds/world-coords";
import { getOrCreateChannel, sendOnChannel } from "~src/online";


export const expl = document.createElement('img');
expl.src=explImage

const tempCanvas = document.createElement('canvas');

export class ObjectBase {
    public debugName=""
    protected _width:number;
    protected _height:number;
    protected layers:number = 1;
    protected _tool:EntityToolBase;
    protected _canvas : HTMLCanvasElement = document.createElement('canvas');
    protected _pos:Vector2d = new Vector2d();
    protected  _bounds: Bounds = new Bounds();

    // =============== getters and setters =================
    public get canvas(){
        return this._canvas;
    }

    public get width(){
        return this._width;
    }

    public get height(){
        return this._height;
    }

    public set width(newWidth:number){
        this._width = newWidth;
    }

    public set height(newHeight:number){
        this._height = newHeight;
    }
    

    protected get bounds(): Bounds {
        return this._bounds;
    }
    protected set bounds(value: Bounds) {
        this._bounds = value;
    }

    // =============== end of getters and setters =================

    public static ObjList:ObjectBase[];
    public static EntList:ObjectBase[];
    protected pixelData:ImageData;
                

    public getCorners(){
        return { topLeft: {x: this.pos.x, y: this.pos.y },
        topRight:{ x: this.pos.x + this.width, y: this.pos.y },
        bottomLeft:{ x: this.pos.x, y: this.pos.y + this.height },
        bottomRight:{ x: this.pos.x + this.width, y: this.pos.y + this.height }  }
    }

    public refreshImageData(){
        // const startTime = performance.now()

        const ctx = this.canvas.getContext('2d');
        
        // collapse the layers
        ctx.clearRect(0,0,this.width,this.height)
        for(let i=this.layers; i>0; i--){
            ctx.drawImage(this.canvas, i*this.width, 0, this.width, this.height, 0, 0, this.width, this.height)
        }
        
        // cache the col data
        
        
        tempCanvas.width = this.width;
        tempCanvas.height = this.height;
        const tempCTX = tempCanvas.getContext('2d');
        tempCTX.drawImage(this.canvas,0,0, this.width, this.height, 0,0, this.width, this.height);
        
        this._bounds.width = this.width;
        this._bounds.height = this.height;
        this.pixelData = tempCTX.getImageData(0,0, tempCanvas.width, tempCanvas.height);
    }

    public setLayers(newLayerCout){
        this.layers = newLayerCout;
    }

    public get pos(){
        return this._pos;
    }
    public set pos(newVec:Vector2d){
        this._pos.x = newVec.x;
        this._pos.y = newVec.y;
    }

    constructor(){
        const ctx = this.canvas.getContext('2d');
    }

    draw(ctx:CanvasRenderingContext2D, canvasPos:CanvasPosition){
        ctx.drawImage(this.canvas, 0,0,this.width*0.99,this.height,
            this.pos.x, this.pos.y,this.width, this.height)
        if(this._tool) this._tool.draw(ctx, canvasPos);
    }

    update(mainCtx:CanvasRenderingContext2D,cameraPos:{x:number, y:number}){
        this.updateTools(mainCtx, cameraPos)
    }

    updateTools(mainCtx:CanvasRenderingContext2D, cameraPos){
        if(this._tool){
            this._tool.update(mainCtx);
        }
    }

    explode(pos:Vector2d,size=10, owner:any){
        ObjectBase.ExplodeAll(pos, size, owner);
    }

    static ExplodeAll(pos:Vector2d,size=10, owner:any, boradcast = true, rands:number[] = null){

        if(!rands){
            rands=[];
            for(let i=0;i<10;i++){ 
                rands.push(Math.random())
            }
        }

        let randCount =0;
        const getRand = ()=>{
            return rands[randCount++ % rands.length]
        }

        if(boradcast) sendOnChannel("explosion", {pos, size, rands});

        if(ObjectBase.ObjList) {
            const objList = ObjectBase.ObjList;
            
            objList.forEach( obj  =>  { if(obj !==owner) obj.receiveExplosion(pos, size, getRand) } );
        }

        if(ObjectBase.EntList) {
            const entList = ObjectBase.EntList;
            entList.forEach( obj => {  if(obj !==owner) obj.receiveExplosion(pos, size, getRand) } );
        }

        
    }

    equipTool(newTool:EntityToolBase) {
        if(this._tool){
            this._tool.unequip();
        }
        this._tool = newTool;
        
        if(this._tool){
            this._tool.equip(this);
        }
    }

    getObjectList():ObjectBase[]{
        return ObjectBase.ObjList
    }

    rayCast(start:Vector2d, end:Vector2d, filter?:(obj:ObjectBase)=>boolean) : {object:ObjectBase, pos: { x:number, y:number, len:number} } {
        const intersects = this.rayCastAll(start,end,filter);
        const sorted = intersects.sort((a,b)=> (a.pos.len - b.pos.len));
            
        const intersect = sorted[0];
        return intersect;
    }

    rayCastAll(start:Vector2d, end:Vector2d, filter?:(obj:ObjectBase)=>boolean) : {object:ObjectBase, pos: { x:number, y:number, len:number}}[]{


        const rayLen = start.distanceTo(end);

        let intersects = [];

        // needs to be fixed
        if(this.getObjectList()) {
            const objList = this.getObjectList();
            
            for(let intIndex = 0; intIndex<objList.length; intIndex++){

                const obj = objList[intIndex];
                
                // if they passed in a filter this is where it kicks in
                if(filter && filter(obj)) continue;

                // get the corners
                const corners = obj.getCorners();
                const objectBounds = new Bounds(corners.topLeft.x,corners.topLeft.y, obj.width, obj.height)
                let pairs = [];

                let addTop = corners.topLeft.y>start.y;

                if(addTop) {
                    pairs.push([corners.topLeft, corners.topRight])
                }
                // otherwise addd the bottom
                else if(corners.bottomLeft.y<=start.y){
                    pairs.push([corners.bottomLeft, corners.bottomRight])
                }

                const addRight = corners.topLeft.x > start.x;
                if(addRight){
                    pairs.push([corners.topLeft, corners.bottomLeft ])
                } else  {
                    pairs.push([corners.topRight, corners.bottomRight])
                }

                // console.log('pairs', pairs)

                // pairs.push([corners.topLeft, corners.topRight])
                // pairs.push([corners.bottomLeft, corners.bottomRight])
                // pairs.push([corners.bottomLeft, corners.topLeft])
                // pairs.push([corners.topRight, corners.bottomRight])

                let objectCollided = false;
                for(let i =0; i<pairs.length;i++){

                    let intersect = null;
                    if(!objectBounds.inBounds(start.x,start.y)) {
                        // console.log('not starting in obj')
                        intersect = lineIntersect(start, end, new Vector2d(pairs[i][0]), new Vector2d(pairs[i][1]))
                    } else {
                        // console.log('INSIDE')
                        const ray = end.minus(start);
                        ray.length = 0.5;
                        ray.add(start);
                        intersect = {x:start.x, y: start.y, len:0.5};
                    }
                    // make sure it's actually on that ray
                    const distToStart = start.distanceTo(intersect)
                    const distToEnd = end.distanceTo(intersect)
                    if(distToStart>rayLen || distToEnd > rayLen) continue;
                    // console.log('in range', intersect)

                    // intersect = lineIntersect(start, end, new Vector2d(corners.topLeft), new Vector2d(corners.bottomLeft))
                    if(intersect
                        && corners.topLeft.x <= intersect.x
                        && corners.topRight.x >= intersect.x 
                        && corners.topLeft.y <= intersect.y 
                        && corners.bottomRight.y >= intersect.y
                        ){
                        
                        // console.log('in bounds')
                        // now go through the pixeldata
                        const pixels = obj.pixelData;
                        if(!pixels) continue;

                        const getPixel = (x,y) => {

                            // console.log('x,y', x,y)

                            x -= obj.pos.x 
                            y -= obj.pos.y
                            x = Math.floor(x);
                            y = Math.floor(y);
                            
                            // if(x==obj.width) x = obj.width-1
                            // if(y==obj.height) y = obj.height-1
                            if(x>=obj.width) return 0;
                            if(x<0) return 0;
                            if(y>=obj.height) return 0;
                            if(y<0) return 0;

                            

                            console.log('new x,y', x,y, obj.width,obj.height, obj.debugName)

                            const heightPixels = obj.width * y;


                            const pixelIndex = ((heightPixels + (x) ) *4) + 3;
                            const totalPixels = obj.width *4*obj.height;
                            console.log('made it past checks',heightPixels, pixelIndex, totalPixels)  
                            if(( pixelIndex >= totalPixels ) 
                                || pixelIndex<0) {
                                
                                return 0;
                            } 
                            // console.log('color', pixels.data[pixelIndex ])
                            return pixels.data[pixelIndex ];
                        }
                        
                        let hitCount = 0;
                        
                        let x = intersect.x;
                        let y = intersect.y;
                        let counter = 0;

                        const cursor = new Vector2d(intersect);
                        const moveAmount:Vector2d = end.minus(cursor);
                        let newLength = 0;

                        moveAmount.normalize();
                        if(Math.abs(moveAmount.y) > Math.abs(moveAmount.x)) {
                            newLength = 1.0 / moveAmount.y
                        } else {
                            newLength = 1.0 / moveAmount.x
                        }
                        moveAmount.length =.01
                        // moveAmount.mult(Math.abs(newLength));
                        // moveAmount.mult(0.5);
                        cursor.add(moveAmount)
                        
                        let beenInBounds = false;
                        bline(cursor.x, cursor.y,end.x,end.y, (x,y)=>{
                            
                            const pixelColor = getPixel(x , y);
                            // console.log('pixelColor', pixelColor)
                            
                            // const pixelColor = getPixel(x , y);
                            // console.log('pixelColor', pixelColor, startPoint);
                            if(pixelColor){
                                intersects.push({object:obj, pos: { x, y, len: start.distanceTo(x,y)} } );
                                objectCollided = true;
                                return false;
                            }
                            let inBounds = objectBounds.inBounds(Math.floor(x),Math.floor(y));
                            
                            if(inBounds) beenInBounds = true;

                            return counter++<10000 
                                && (inBounds || !beenInBounds)
                        })
                        // while(counter++<10000 && bounds.inBounds(cursor.x,cursor.y)) {
                            
                        //     const pixelColor = getPixel(cursor.x , cursor.y);
                        //     // console.log('pixelColor', pixelColor)
                            
                        //     // const pixelColor = getPixel(x , y);
                        //     // console.log('pixelColor', pixelColor, startPoint);
                        //     if(pixelColor){
                        //         intersects.push({object:obj, pos: { x:cursor.x, y:cursor.y, len: start.distanceTo(cursor.x,cursor.y)} } );
                        //         objectCollided = true;
                        //         break;
                        //     }
                        //     cursor.add(moveAmount)
                                
                        //     // if (x === end.x && y === end.y) break;
                        //     // if( (Math.abs(end.x - x) > obj.width) || (Math.abs(end.y - y) > obj.height)) break;
                        //     // var e2 = err;
                        //     // if (e2 > -dx) { err -= dy; x += sx; }
                        //     // if (e2 < dy) { err += dx; y += sy; }
                            
                        // }
                        if(!objectCollided) {
                            console.log('didnt collide')
                        }
                        if(objectCollided) continue;
                    }
                }
                
            }
        }
        
        // console.log('intersects', intersects, start, end)

        return intersects;


        // if(ObjectBase.EntList) {
        //     const entList = ObjectBase.EntList;
        //     entList.forEach( obj => {  if(obj !==owner) obj.receiveExplosion(pos, size) } );
        // }
        
    }

    receiveExplosion(pos:Vector2d, size:number, getRand:()=>number = Math.random){
        if(pos.x > this.pos.x + this.width + size) return;
        if(pos.x < this.pos.x - size) return;


        const ctx = this.canvas.getContext('2d');
        const relativeLocation = pos.clone();
        relativeLocation.sub(this.pos);
        ctx.save();

        
        // ctx.beginPath();
        // ctx.arc(relativeLocation.x,relativeLocation.y,size,0, Math.PI*2);
        // ctx.clip();
        // ctx.fillStyle= "#000"
        ctx.globalCompositeOperation = 'destination-out'
        // ctx.closePath();
        // ctx.fill();

        // ctx.translate(relativeLocation.x , relativeLocation.y);
        

        for(let i=1; i<this.layers+1 && size>=1; i++){
            
            ctx.save();
                ctx.beginPath();
                ctx.rect(this.width*i,0,this.width, this.height)
                ctx.clip(); 
            
            
            ctx.translate(relativeLocation.x + this.width*i, relativeLocation.y);
        
            
            let angle =getRand()*Math.PI*2
            
            
            ctx.rotate(angle);
            // ctx.scale(1 + .1* Math.random(),1 + .1* Math.random())
            
            ctx.drawImage(expl, -size, -size, size*2, size*2);
            // ctx.rotate(-angle);
            let newSize = size *0.5;
            if(size - newSize< 5) newSize = size - 5;
            size = newSize;
            // ctx.clearRect(relativeLocation.x-size, relativeLocation.y-size, size*2, size*2 );
            ctx.translate( - (relativeLocation.x + this.width*i), -relativeLocation.y);
            ctx.restore();
        }

        ctx.restore();

        this.refreshImageData();
    }

    protected static evtQueue:any[] = [];

    public static init(){

        const expChannel = getOrCreateChannel("explosion", (evt)=>{
            // console.log('explosion send')
            const expData = JSON.parse(evt.data);
            ObjectBase.ExplodeAll(new Vector2d(expData.pos), expData.size, null, false, expData.ra)
        });

        const objChannel = getOrCreateChannel("objData", (evt)=>{
            const objData = JSON.parse(evt.data); 
            // console.log('objData')
            ObjectBase.evtQueue.push(objData)
        });
    }
}




const lineIntersect = (point1:Vector2d, point2:Vector2d, point3:Vector2d, point4:Vector2d) :{x:number,y:number, len:number} =>{

    let deltaX1 = (point2.x-point1.x);
    let deltaX2 = (point4.x-point3.x);

    if( (deltaX1 == 0) && (0 == deltaX2)) return null;

    // edge cases
    if(deltaX1==0 ){
        const slope2 =  (point4.y-point3.y)/deltaX2;
        const b2 = point3.y - slope2*point3.x;
        const x = point1.x;
        const y = slope2 * x + b2;
        return {x,y, len:point1.distanceTo({x,y})};
    }

    if(deltaX2==0 ){
        const slope1 =  (point2.y-point1.y)/deltaX1;
        const b1 = point1.y - slope1*point1.x;
        const x = point3.x;
        const y = slope1 * x + b1;
        return {x,y, len:point1.distanceTo({x,y})};
    }

    if(deltaX2 == 0) deltaX2=0.00001;

    const slope1 =  (point2.y-point1.y)/deltaX1;
    const slope2 =  (point4.y-point3.y)/deltaX2;

    const b1 = point1.y - slope1*point1.x;
    const b2 = point3.y - slope2*point3.x;

    const intX = (b2-b1) / (slope1-slope2);
    const intY = slope1*intX + b1;

    return { x:intX, y:intY, len:point1.distanceTo(intX,intY)};
}


// Refer to: http://rosettacode.org/wiki/Bitmap/Bresenham's_line_algorithm#JavaScript
function bline(x0, y0, x1, y1, onDraw: (x,y)=>boolean) {
    var dx = Math.abs(x1 - x0),
        sx = x0 < x1 ? 1 : -1;
    var dy = Math.abs(y1 - y0),
        sy = y0 < y1 ? 1 : -1;
    var err = (dx > dy ? dx : -dy) / 2;
    while (onDraw(x0, y0)) {
        
        if (x0 === x1 && y0 === y1) break;
        var e2 = err;
        if (e2 > -dx) {
            err -= dy;
            x0 += sx;
        }
        if (e2 < dy) {
            err += dx;
            y0 += sy;
        }
    }
}