


export class Vector2d{
    public x:number;
    public y:number;

 
    constructor(obj:{x:number,y:number});
    constructor(x:number,y:number);
    constructor();

    constructor(x:number|{x:number,y:number}=0, y:number=0) {
        if(isNaN(x as any)) {
            const coords = x as {x:number,y:number}
            this.x = coords.x;
            this.y = coords.y;
        } else {
            this.x = x as number;
            this.y = y;
        }
    }

    

    public get normalized(){
        const distance = this.length;
        return new Vector2d(this.x / distance, this.y / distance)
    }

    public get length(){
        return Math.sqrt(this.x**2 + this.y**2);
    }

    public set length(newLength:number){

        this.normalize();
        this.x = this.x * newLength;
        this.y = this.y * newLength;
    }

    // =======================
    //      arithmatic
    // =======================
    /**
     * Chainable function for adding to the current vector
     * @param x 
     * @param y 
     */
    public add(x:number, y:number) :Vector2d;
    public add(vec:{x:number, y:number}):Vector2d;
    public add(x:number|{x:number, y:number}, y?:number):Vector2d{
        // if they passed an object get the x and y from there
        if(isNaN(x as any)){
            const vec = x as {x:number, y:number};
            y = vec.y;
            x = vec.x;
        }
        // by this point x is a number
        this.x += x as number;
        this.y += y;
        return this;
    }

    // =======================
    //      arithmatic
    // =======================
    /**
     * Chainable function for adding to the current vector
     * @param x 
     * @param y 
     */
    public plus(x:number, y:number);
    public plus(vec:{x:number, y:number});
    public plus(x:number|{x:number, y:number}, y?:number){
        // if they passed an object get the x and y from there
        if(isNaN(x as any)){
            const vec = x as {x:number, y:number};
            y = vec.y;
            x = vec.x;
        }
        return new Vector2d(this.x + (x as number), this.y+y);
    }

    
    public sub(vec:{x:number, y:number} ){
        this.x -= vec.x;
        this.y -=vec.y;
        return this;
    }

    public minus(vec:Vector2d ){
        return new Vector2d(this.x-vec.x, this.y-vec.y);
    }

    public mult(newLength:number){
        this.x*=newLength;
        this.y*=newLength;
        return this;
    }

    // ========================================
    //          distanceTo
    // ========================================
    public distanceTo(vec:{x:number, y:number} );
    public distanceTo(x:number, y:number );

    public distanceTo(x:number|{x:number, y:number}, y?:number){
        // if they passed an object get the x and y from there
        if(isNaN(x as any)){
            const vec = x as {x:number, y:number};
            y = vec.y;
            x = vec.x;
        }
        return Math.sqrt((this.x- (x as number))**2 + (this.y-y)**2);
    }



    public normalize(){
        const len = this.length;
        if(!len) return;
        this.x = this.x / len;
        this.y = this.y / len;
    }


    public lerp(vec, amount=.5){
        this.x*=(1.0-amount);
        this.x += vec.x*amount;

        this.y*=(1.0-amount);
        this.y += vec.y*amount;
    }

    public clone(){
        return new Vector2d(this.x, this.y)
    }

}