import { Vector2d } from "./modules/bounds/vector2d";
import "./online"
import { ObjectBase } from "./modules/engine/object/engine-object-base";
import { Block } from "./modules/engine/object/engine-object-block";
import { LayeredObject } from "./modules/engine/object/engine-object-layered";
import { Player } from "./modules/engine/object/engine-object-player";
import head from '~/img/head.png'
import enemyImage from '~/img/enemy.png'
import turretImage from '~/img/turret.png'
import { Ice } from "./modules/engine/object/engine-object-ice";
import { RigidBody } from "./modules/engine/object/engine-object-rigid-body";
import { Turret } from "./modules/engine/object/engine-object-turret";
import { CanvasPosition } from "./modules/bounds/world-coords";



const canvas : HTMLCanvasElement = document.createElement('canvas');

const bufferCanvas : HTMLCanvasElement = document.createElement('canvas');

canvas.style.width = "100vw"
canvas.style.height = "100vh"
bufferCanvas.style.width = "100vw"
bufferCanvas.style.height = "100vh"


const canvasPos = new CanvasPosition(canvas);

document.body.appendChild(canvas);



// ================ window resizing ================
const resizeWindow = ()=>{
    canvas.width = canvas.clientWidth;
    canvas.height = canvas.clientHeight;
    bufferCanvas.width = canvas.clientWidth;
    bufferCanvas.height = canvas.clientHeight;
}

window.addEventListener("resize", resizeWindow);
resizeWindow();
// =================================================

//====================================
//          set up the environment
//====================================
const objects = [];

const player = new Player(canvasPos);

const bigFloor = new Block(0, 850, 1000, 120);
bigFloor.debugName="big floor"
// add a couple blocks

let shortBlock = new Block(600, 500, 400, 30);
let tallBlock = new Block(-80, 30, 60, 800);
objects.push(new Block(0, 500, 400, 120),
    tallBlock,
    bigFloor,
    new Block(380, 600, 400, 120),
    shortBlock,
    new Block(800, 400, 20, 300),
    new Block(-30, 550, 100, 300),
    // new Ice(1000, 300, 400, 90),
    new LayeredObject(500, 0, head, 4),
    
    // new LayeredObject(500, 320, enemyImage,3, 50, 100),
    // new Turret(500, 0, turretImage,2, 100, 100, player),
    // new RigidBody(50,0),
    player
    )




const ents = [];
tallBlock.debugName="TALL"
player.debugName="PLAYER"
shortBlock.debugName="SHORT"


// ents.push(player);
// ents.push(new RigidBody(50,0));

ObjectBase.EntList = ents;
ObjectBase.ObjList = objects;
ObjectBase.init();
let lastDraw = performance.now()

// =================================================
//              DRAW THE WHOLE SCENE
// =================================================
const draw = ()=>{


    lastDraw = performance.now()
    const ctx = bufferCanvas.getContext('2d');

    // ctx.clearRect(0,0,canvas.width, canvas.height);
    resizeWindow();
    ctx.save();
    
    const lastPlayerPos = player.pos.clone();
    

    // draw everything that's dumb
    objects.forEach(object => {
        object.update(ctx, canvasPos)
    });
    // objects.forEach(object => );

    canvasPos.x = player.pos.x- canvas.width/2;
    canvasPos.y = player.pos.y-canvas.height/2;

    ctx.translate(-canvasPos.x,-canvasPos.y)

    objects.forEach(object => {
        object.draw(ctx);
    });
    
   


    // objects.forEach(object => {
    //     // object.update(ctx,{x:player.pos.x- canvas.width/2, y:player.pos.y- canvas.height/2})
    //     object.drawTool(ctx);
    // });
    ctx.restore();
    
    // ctx.translate(canvas.width/2 - player.pos.x, canvas.height/2-player.pos.y)
    
    //draw the living entities
    // ents.forEach(object => object.draw(ctx));

    // ctx.translate( player.pos.x - canvas.width/2, player.pos.y - canvas.height/2)




    const mainCtx = canvas.getContext('2d');
    

    // const oldComposite = mainCtx.globalCompositeOperation;
    mainCtx.save();
    mainCtx.globalCompositeOperation = "copy";
    
    mainCtx.drawImage(bufferCanvas,0,0);
    mainCtx.restore();

    window.requestAnimationFrame(draw)
    
}

window.requestAnimationFrame(draw)












