import { Vector2d } from "~src/modules/bounds/vector2d";
import { ObjectBase } from "./engine-object-base";

import iceImage from '~/img/ice.png'


const iceTexture = document.createElement('img');
iceTexture.src=iceImage;
const expansionCanvas = document.createElement('canvas');
let loaded = false;
export class Ice extends ObjectBase{

    

    constructor(x,y, width:number, height:number) {
        super();
        this.width  =   width;
        this.height =   height;
        this.setLayers(1);

        this.canvas.width = width * (this.layers+1);
        this.canvas.height = height;

        this.pos = new Vector2d(x-width/2,y - height/2);

        window.setTimeout(()=>{
            this.drawIce(this.width*0.5, this.height*0.5, this.width)
            this.expandCanvas(50,0)
            this.expandCanvas(50,0)
        }, 100);


    }

    drawIce(x,y,size){
        console.log('draw ice')
        const ctx = this.canvas.getContext('2d');
        // ctx.translate(this.width,0);

        loaded = true;
        ctx.save();

    // ctx.fillStyle="#884411"
        ctx.rect(0, 0, this.width, this.height);
        const angle = Math.PI*2*Math.random();
        ctx.translate(this.width + x , y)
        ctx.rotate(angle)
        // ctx.translate(-this.width/2,-this.height/2)

        ctx.drawImage(iceTexture, 
            0,0,iceTexture.width,iceTexture.height,
            -size/2, -size/2, this.width, this.height);

        ctx.rotate(-angle)
        ctx.restore();


        this.refreshImageData();
        
    }


    addIce(x,y, size = 10){

        // figure out if we need to expand
        const right = this.pos.x+this.width;
        const newRight = x + size/2.0;
        if( newRight> right) {
            console.log('EXPANDING RIGHT', newRight - right)
            this.expandCanvas(newRight-right, 0)
        }

        this.drawIce(x-this._pos.x, y-this._pos.y, size);
    }

    expandCanvas(x,y){


        const oldWidth = this.width ;
        const oldHeight = this.height ;

        // copy the current canvas
        expansionCanvas.width = this.width * (this.layers+1);
        expansionCanvas.height = this.height;
        const tempCtx = expansionCanvas.getContext('2d');

        tempCtx.clearRect(0,0,expansionCanvas.width,expansionCanvas.height);
        tempCtx.drawImage(this.canvas,0,0)

        // expand the canvas
        this.width = this.width + x;
        this.height = this.height + y;

    
        let xOffset = 0;
        let yOffset = 0;

        //redistribute the layers
        this.canvas.width = this.width*(this.layers+1);
        this.canvas.height = this.height;

        
        // recopy each layer
        const ctx = this.canvas.getContext('2d');
        ctx.save();
        for(let i=1;i<this.layers+1;i++){
            ctx.translate(this.width,0);
            ctx.drawImage(expansionCanvas,i*oldWidth, 0, oldWidth, oldHeight,
                    xOffset,yOffset, oldWidth,oldHeight)
        }
        ctx.restore();
        this.refreshImageData();
    }

}