import { Block } from "../../object/engine-object-block";
import { Player } from "../../object/engine-object-player";
import { EntityToolBase } from "./engine-entity-tool-base";

const factories = [
    {name:"block", points:2, factory: points=>new Block(points[0].x,points[0].y, Math.floor(points[1].x-points[0].x), Math.floor(points[1].y-points[0].y)) }
]
export class EntityLibraryTool extends EntityToolBase{


    currentClick=0;
    _currentIndex = 0;

    equip(newHost:Player){
        super.equip(newHost)

        // this.listen('mousedown',
        // (event:MouseEvent)=>{
        //     this._mouseDown = true;
        //     console.log('mousedown')
        //     let dpi = window.devicePixelRatio;
        //     this._mousePos.x = event.pageX * dpi - window.document.body.clientWidth/2;
        //     this._mousePos.y = event.pageY * dpi - window.document.body.clientHeight/2;
        // });

        
    }

    down = false;
    points=[];
    update(){


        // take care of adding points
        if(this._activate){
            if(!this.down){
                this.down=true;
            }
        } else {
            if(this.down) {
                this.points.push(this.getFocusPoint().clone())
                if(this.points.length == factories[this._currentIndex].points ){
                    this._host.getObjectList().push(factories[this._currentIndex].factory(this.points));
                    this.points = [];
                }
            }
            this.down = false;
        }
    }

    unequip(){
        super.unequip();
    }

    draw(ctx:CanvasRenderingContext2D){

        if(!this.points) return;

        if(this.points.length<1) return;
        // first point
        ctx.beginPath();
        const p = this.points[0];
        ctx.lineWidth =3;
        ctx.strokeStyle = "#88ffff"
        ctx.arc(p.x,p.y,5, 0,Math.PI*2)
        ctx.stroke();
        
        // if(this.points.length<2) return;


        const p1 = this.getFocusPoint();
        const width = p1.x - p.x;
        const height = p1.y - p.y;
        ctx.lineWidth =3;
        ctx.strokeStyle = "#88ffff"
        ctx.strokeRect(p.x, p.y, width, height)
            
    
        

    } 
}