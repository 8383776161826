import { Vector2d } from "./vector2d";


export interface PointData {
    x?:number,
    y?:number,
    z?:number
}

export class CanvasPosition{

    protected _x:number
    protected _y:number
    protected _z:number
    protected _zoom:number = 1.0;
    protected _canvas:HTMLCanvasElement;
    protected _dpi :number;

    constructor(canvas :HTMLCanvasElement){
        this._x=0;
        this._y=0;
        this._canvas = canvas;
        this._dpi = window.devicePixelRatio;
        
    }


    public paperCoords( x :  PointData | number, y?:number) : Vector2d {
        
        let retVal;
        
        if( y && x){
            retVal = {x, y, z:0};
        } else {
            const inputData = x as PointData;
            retVal = {x:0, y:0, z:0, ...inputData};
        }

        retVal.y += -this._canvas.offsetTop; 
        // retVal.y *= this._dpi;
        retVal.y += this.y ;
        retVal.y /= this._zoom;

        retVal.x += -this._canvas.offsetLeft
        // retVal.x *= this._dpi;
        retVal.x += this.x;
        retVal.x /= this._zoom;
        
        return new Vector2d(retVal);
    }


    public translate(point:PointData) : void {
        if(point.x) this._x+=point.x;
        if(point.y) this._y+=point.y;
    }

    public setZoom(zoomLevel:number, anchorPoint : {x:number, y:number} = null) : void {

        // if no anchorpoint is provided then zoom on the center
        if(anchorPoint==null) {
            anchorPoint = {x: this._canvas.clientWidth/2 , y:this._canvas.clientHeight/2 + this._canvas.offsetTop};
        }

        const currentZoom = this._zoom;
        const currentPos = this.paperCoords(anchorPoint);

        this._zoom = zoomLevel ;
        const newPos = this.paperCoords(anchorPoint);

        this.translate({x: (newPos.x - currentPos.x )*zoomLevel, y: ( newPos.y - currentPos.y )*zoomLevel})
    }



    public getCanvasBounds(){
        const topLeft = this.paperCoords(this._canvas.offsetLeft, this._canvas.offsetTop);
        const bottomRight = this.paperCoords(this._canvas.offsetLeft + this._canvas.clientWidth, this._canvas.offsetTop + this._canvas.clientHeight);
        return { x: topLeft.x, y: topLeft.y, width:bottomRight.x - topLeft.x, height:bottomRight.y - topLeft.y}
    }

    public get zoom() : number {
        return this._zoom;
    }

    public get dpi() : number {
        return this._dpi;
    }

    public get x() : number {
        return this._x
    }
    
    public set x(val:number)  {
        this._x = val;
    }
    

    public get y() : number {
        return this._y;
    }
    
    public set y(val:number)  {
        this._y = val;
    }
}