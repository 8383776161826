

export class DelayTimer {
    
    protected _delay = 0;
    protected _lastTime = 0;

    constructor(delay=0, startReady=true){
        this._delay = delay;
    }

    get currentTime(){
        return performance.now() - this._lastTime;
    }

    percentDone( capped=true){
        if(capped) return Math.min(1.0,this.currentTime/this._delay)
        return this.currentTime / this._delay;
    }

    isReady(){
        return this.currentTime >= this._delay;
    }

    reset(){
        this._lastTime = performance.now();
    }


}